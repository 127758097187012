<template>
  <div class="wrap">
    <div class="left">
      <div class="title-wrap">
        <img class="img-back"
             src="@/assets/personalCenter/icon_back.png"
             @click="backClick" />
        <div class="title-txt">购买套餐</div>
        <img class="img-back"
             style="visibility: hidden;" />
      </div>
      <div class="vip-pack-wrap"
           v-for="(item,index) in vipPackList"
           :key="index">
        <div class="pack-title">{{ item.title }}</div>
        <div class="pack-item-wrap">

          <div :class="[(selPack&&(selPack.id == childItem.id))?'pack-item-active':'pack-item']"
               v-for="(childItem,childIndex) in item.child"
               :key="childIndex"
               @click="changePack(childItem)">
            <div :style="selPack && selPack.id == childItem.id ? { 'background': 'linear-gradient(130deg, rgba(246, 84, 82, 0.10) 0%, rgba(242, 143, 26, 0.10) 100%), #FFF','border-radius':'16rem',

    'width': '226rem',
    'height': '202rem',
    'display': 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
}  : {}">
              <div class="font-24-333-600 pack-item-title">{{ childItem.name }}</div>
              <div class="price-wrap">
                <span class="font-40-111-600" :style="{'color' : (selPack && selPack.id == childItem.id )?'#F37F21':'#333333'}">{{ childItem.price }}</span>
                <span class="font-20-666-400">元/份</span>
              </div>
              <div class="font-20-999-400"
                   :style="{'text-decoration-line': 'line-through', 'visibility':(Number(childItem.origin_price) == Number(childItem.price))?'hidden':'visible'}">
                {{ childItem.origin_price }}元/份
              </div>
              <div class="font-20-fff-600 pack-tag-wrap"
                   v-if="childItem.new_user">新用户专享</div>
            </div>

            <div class="font-20-fff-600 pack-tag-wrap"
                 v-if="childItem.new_user">新用户专享</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="vip-info-wrap">
        <img class="vip-logo"
             src="@/assets/personalCenter/icon_vip_logo.png" />
        <img class="vip-txt-desc"
             src="@/assets/personalCenter/icon_self_vip.png" />
        <div>
          <span class="font-24-3C2003-400"
                style="opacity: 0.8;margin-top:12px;">平台版</span>
          <span class="font-24-3C2003-400"
                style="opacity: 0.8;margin-top:12px;"
                v-if="selPack">·{{ selPack?selPack.name:'' }}</span>
        </div>
        <div class="font-20-3C2003-400"
             :style="{'opacity': '0.6', 'margin-top': '157px', 'visibility': 'hidden'}">2024年01月03日到期</div>
        <img class="img-head"
             :src="userInfo.avatar"
             v-if="userInfo.avatar" />
        <img class="img-head"
             src="@/assets/personalCenter/头像.png"
             v-else />
        <img class="img-vip-hat"
             src="@/assets/personalCenter/icon_vip_hat.svg" />
      </div>
      <div class="benefits-wrap"
           v-if="selPack">
        <div class="font-20-1A1A1A-600">{{ selPack.name }}</div>
        <div class="benefits-item-wrap"
             v-if="selPack.detection">
          <span class="font-24-666-400"
                v-html="selPack.detection"></span>
          <!-- <div class="benefits-item" v-for="(item, index) in benefits.list" :key="index">
            <img class="img-gou" src="@/assets/personalCenter/icon_vip_gou.svg" />
            <span class="font-24-666-400">{{ item.title }}</span>
          </div> -->
        </div>
        <div class="btn-buy"
             @click="toBuy">立即购买 {{ selPack.price }}元</div>
      </div>
    </div>
    <QRCodeDialog ref="dialogRef"
                  @paySuccessEvent="paySuccessEvent"></QRCodeDialog>
  </div>
</template>

<script>
import QRCodeDialog from '@/views/vip/components/buyQRCode.vue'
import { getVipPack } from '@/api/vip.js'
import { getUserInfo } from '@/api/userInfo.js'
import { getVipInfo } from '@/api/vip.js'

export default {
  data () {
    return {
      userInfo: {},
      selPack: null,
      vipPackList: [
        // {
        //   title:'平台版',
        //   child:[
        //     {
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费士大夫大师傅士大夫萨芬撒旦法发',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费士大夫大师傅士大夫萨芬撒旦法发',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     }
        //   ]
        // },
        // {
        //   title:'平台版',
        //   child:[{
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费士大夫大师傅士大夫萨芬撒旦法发',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     },
        //     {
        //       title:'免费士大夫大师傅士大夫萨芬撒旦法发',
        //       originPrice:'6',
        //       salePrice:'6',
        //       unit:'元/份'
        //     }
        //   ]
        // }
      ],
      benefits: {
        title: '个性化平台教辅·1科AI定制教辅',
        list: [
          {
            title: '邮寄18套纸质版卷子'
          },
          {
            title: '后台系统+真人老师改卷'
          },
          {
            title: '纸质版学情报告'
          },
          {
            title: '精准纸质版个性化专属习题集+答案精准纸质版个性化专属习题集+答案'
          }
        ]
      }
    }
  },
  components: {
    QRCodeDialog
  },
  methods: {
    backClick () {
      this.$router.go(-1)
    },
    async getInfo () {
      const { data } = await getUserInfo()
      let form = {
        sex_id: data.sex_id,
        avatar: data.avatar,
        truename: data.truename,
        nickname: data.nickname,
        edu_grade_id: data.edu_grade_id,
        admission_ticket: data.admission_ticket

      }
      this.$set(this, 'userInfo', form)
    },
    toBuy () {
      if (!this.selPack) {
        this.$message({
          message: '请选择购买的套餐！',
          type: 'error'
        })
        return
      }
      this.$refs.dialogRef.showPaycode(this.selPack)
    },
    changePack (item) {
      this.selPack = item
    },
    loadVipPack () {
      let that = this
      getVipPack().then(res => {
        let obj = { title: '平台版' }
        let list = res.data.list
        obj.child = list
        that.vipPackList.push(obj)
      })
    },
    async paySuccessEvent () {
      const { data } = await getVipInfo()
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      userInfo.vip_end_time = data.vip_end_time
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.$message({
        message: '购买成功!',
        type: 'success'
      })
      this.$router.go(-1)
    }
  },
  created () {
    this.getInfo()
    this.loadVipPack()
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  width: 100%;
  min-height: 100%;
  // margin-top: -100rem;
  background: #fff;

  .left {
    flex: 1;
    display: flex;
    padding: 40rem;
    gap: 40px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    border-right: 1px solid #eee;
    .title-wrap {
      display: flex;
      height: 80rem;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
      .img-back {
        width: 48px;
        height: 48px;
      }
      .title-txt {
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        color: #333;
      }
    }
    .vip-pack-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24rem;
      align-self: stretch;
      .pack-title {
        font-size: 32rem;
        font-style: normal;
        font-weight: 600;
        line-height: 48rem; /* 150% */
        color: #1a1a1a;
      }
      .pack-item-wrap {
        width: 100%;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 24rem;
        .pack-item {
          min-width: calc((100% - 3 * 24rem) / 4);
          max-width: calc((100% - 2 * 24rem) / 3);
          display: flex;
          position: relative;
          padding: 40rem 24rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 16rem;
          background: #fff;
          border: 1rem solid #ccc;
          box-sizing: border-box;
          .pack-item-title {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .price-wrap {
            display: flex;
            align-items: baseline;
          }
          .pack-tag-wrap {
            display: flex;
            padding: 3px 12px;
            align-items: center;
            position: absolute;
            left: -1px;
            top: -16px;
            border-radius: 16px 16px 16px 0px;
            background: linear-gradient(102deg, #fe504d 0%, #f38e19 100%);
          }
          &:hover {
            cursor: default;
          }
        }
        .pack-item-active {
          // min-width: calc((100% - 3 * 24rem) / 4);
          max-width: calc((100% - 2 * 24rem) / 3);
          display: flex;
          position: relative;
          padding: 2rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 16rem;
          border: 1rem solid #ccc;
          box-sizing: border-box;
          .pack-item-title {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .price-wrap {
            display: flex;
            align-items: baseline;
          }
          .pack-tag-wrap {
            display: flex;
            padding: 3px 12px;
            align-items: center;
            position: absolute;
            left: -1px;
            top: -16px;
            border-radius: 16px 16px 16px 0px;
          }
          &:hover {
            cursor: default;
          }
          background: linear-gradient(102deg, #fe504d 0%, #f38e19 100%);
        }
        .pack-item-focus {
          background: red;
        }
      }
    }
  }
  .right {
    width: 40%;
    min-height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 32px;
    align-self: stretch;

    .vip-info-wrap {
      width: 100%;
      display: flex;
      padding: 24px;
      position: relative;
      flex-direction: column;
      box-sizing: border-box;
      // background-image: url("./../../../assets/personalCenter/bg_buy_vip.png");
      background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
      // object-fit: cover;
      border-radius: 16px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      .vip-logo {
        height: 178px;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
      .vip-txt-desc {
        width: 168px;
        height: 40px;
      }
      .img-head {
        width: 112px;
        height: 112px;
        border-radius: 112px;
        background: url(<path-to-image>),
          lightgray -10.202px -1.678px / 112.5% 168.75% no-repeat;
        position: absolute;
        right: 24px;
        top: 24px;
      }
      .img-vip-hat {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 24px;
        bottom: 24px;
      }
    }
    .benefits-wrap {
      width: 100%;
      height:200px;
      flex:1;
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      gap: 24px;
      border-radius: 16px;
      border: 1px solid #eee;
      background: #f3f4f6;
      .benefits-item-wrap {
        display: flex;
        height: 280px;
        padding: 24px;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        background: #fff;
        box-sizing: border-box;
        .benefits-item {
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;
        }
      }
      .btn-buy {
        display: flex;
        padding: 9px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
        color: #3c2003;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px; /* 150% */
        &:hover {
          cursor: default;
        }
      }
    }
  }
}
.font-24-333-600 {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: #333;
}
.font-40-111-600 {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 150% */
  color: #111;
}
.font-20-666-400 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  color: #666;
}
.font-20-999-400 {
  color: #999;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.font-20-fff-600 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.font-24-3C2003-400 {
  font-size: 24px;
  color: #3c2003;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.font-20-3C2003-400 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  color: #3c2003;
}
.font-20-1A1A1A-600 {
  color: #1a1a1a;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 150% */
}
.font-24-666-400 {
  color: #666;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  overflow-y: auto;
  height: 398px;
}
</style>